<template>
	<form class="u-newsletter-form" @submit.prevent="handleSubmit()" novalidate="true">
		<div class="u-newsletter-form__meta-wrapper">
			<img class="u-newsletter-form__icon" :src="newsletterIcon" v-if="newsletterIcon" />
			<div class="u-newsletter-form__title-wrapper">
				<span class="u-newsletter-form__title fw-bold d-block" v-text="adjustedTitle"></span>
				<AtomsNewsletterNotificationTime
					class="u-newsletter-form__notification-time"
					:newsletterNotificationTime="newsletterNotificationTime"
					v-if="newsletterNotificationTime"
				></AtomsNewsletterNotificationTime>
			</div>
			<span class="u-newsletter-form__text d-block mt-2 fs-4 lh-sm" v-text="formText"></span>
		</div>

		<AtomsSelectableTags
			class="u-newsletter-form__tags"
			name="tags"
			:tagList="tagList"
			v-if="tagList && response.status !== 'success'"
			:error-message="formAboList.errorMessage"
			:isValidated="formAboList.isValidated"
			@change="transferList(formAboList.fieldValue)"
			v-model:selected-tags="formAboList.fieldValue"
		></AtomsSelectableTags>
		<slot name="interval"></slot>

		<div class="u-newsletter-form__input-wrapper" v-if="userObjIncomplete && response.status !== 'success'">
			<AtomsSelect
				label="Anrede"
				name="salutation"
				:options="salutationOptions"
				v-model="formSalutation.fieldValue"
				:error-message="formSalutation.errorMessage"
				:isValidated="formSalutation.isValidated"
				emptyOptionLabel="Bitte wählen"
				@change="formSalutation.validate"
				:required="true"
			></AtomsSelect>
			<AtomsInputText
				name="firstName"
				label="Vorname"
				v-model="formFirstName.fieldValue"
				:error-message="formFirstName.errorMessage"
				:isValidated="formFirstName.isValidated"
				@change="formFirstName.validate"
				:required="true"
			></AtomsInputText>
			<AtomsInputText
				name="lastName"
				label="Nachname"
				v-model="formLastName.fieldValue"
				:error-message="formLastName.errorMessage"
				:isValidated="formLastName.isValidated"
				@change="formLastName.validate"
				:required="true"
			></AtomsInputText>
			<AtomsInputText
				name="email"
				label="E-Mail-Adresse"
				v-model="formEmail.fieldValue"
				:error-message="formEmail.errorMessage"
				:isValidated="formEmail.isValidated"
				@change="formEmail.validate"
				:required="true"
			></AtomsInputText>
		</div>
		<AtomsToggleSwitch
			name="terms"
			v-model="formTerms.fieldValue"
			@change="formTerms.validate"
			:required="true"
			:error="!!formTerms.errorMessage"
			:isValidated="formTerms.isValidated"
			class="u-newsletter-form__agreement"
			v-if="response.status !== 'success'"
			>{{ formAgreementText }}</AtomsToggleSwitch
		>

		<AtomsBaseCTA
			class="u-newsletter-form__submit-button"
			version="primary"
			:disabled="aboCount === 0 ? true : false"
			type="submit"
			v-if="response.status !== 'success'"
		>
			{{ formButtonLabel }}</AtomsBaseCTA
		>
		<div class="my-4 text-center" v-if="response?.status">
			<div class="mb-2 font-set-3-bold" v-if="response.status === 'success'">{{ response.title }}</div>
			<p :class="`font-set-2-bold text-color-${response.status}`" v-if="response?.message">{{ response?.message }}</p>
		</div>
		<span class="u-newsletter-form__info-text" v-text="formInfoText"></span>
	</form>
</template>

<script setup lang="ts">
	import { computed, ref } from "vue";
	import type { Ref } from "vue";
	import Validate from "../server/utils/form-validator";

	export interface INewsletterForm {
		countAbos?: number;
		formAgreementText?: string;
		formButtonLabel?: string;
		formInfoText?: string;
		formText?: string;
		formTitle?: string;
		newsletterIcon?: string;
		newsletterNotificationTime?: string;
		tagList?: { title: string; key: string }[];
		loggedIn?: boolean;
		newsletterCardAbos?: string[];
		interval?: string;
		terms?: boolean;
		userData?: { salutation?: string; firstName?: string; lastName?: string; email?: string };
		formKey?: string;
		response?: { title?: string; message?: string; status?: string };
	}

	const props = withDefaults(defineProps<INewsletterForm>(), {
		countAbos: 0,
		formKey: "",
		formTitle: "Kein Newsletter ausgewählt",
		formText: "Melden Sie sich jetzt unkompliziert an und bleiben Sie automatisch informiert.",
		formButtonLabel: "Jetzt kostenlos anmelden",
		loggedIn: false,
		terms: false,
		newsletterCardAbos: () => [],
		response: {
			title: "Ihre Anmedlung war erfolgreich",
			message:
				"Danke für Ihre Bestellung, Ihre Anmeldung war erfolgreich! In Kürze erhalten Sie eine Aktivierungsmail. Bitte beachten Sie: Wenn Sie Ihre E-Mail-Adresse schon einmal bei uns bestätigt haben, erhalten Sie keine Aktivierungsmail mehr.",
			status: null,
		},
	});

	const emit = defineEmits(["error", "submit"]);

	const isValidated = ref(false);
	const formErrorMessage = ref("");
	const salutationOptions = ref(["Herr", "Frau"]);

	const adjustedTitle = computed(() => {
		if (props.countAbos === 0 || props.countAbos === -1) return props.formTitle;
		else if (props.countAbos && props.countAbos > 0) return props.countAbos + props.formTitle.split("Kein")[1];
	});

	const aboCount = computed(() => {
		return aboList.value.length ? aboList.value.length : props.countAbos;
	});

	const aboList: Ref<string[]> = props.newsletterIcon ? ref([props.formKey]) : ref([]);

	function checkSalutation() {
		if (props.userData?.salutation && salutationOptions.value.includes(props.userData?.salutation)) {
			return props.userData?.salutation;
		}

		return "";
	}

	// validation
	const formAboList = useFormValue({
		default: aboList.value,
		validate: (field) => Validate.aboList(field, "Bitte wählen Sie mindestens ein Newsletter aus"),
	});
	const formSalutation = useFormValue({
		default: checkSalutation(),
		validate: (field) => Validate.required(field, "Bitte geben Sie Ihre Anrede ein"),
	});
	const formFirstName = useFormValue({
		default: props.userData?.firstName,
		validate: (field) => Validate.required(field, "Bitte geben Sie Ihren Vornamen ein"),
	});
	const formLastName = useFormValue({
		default: props.userData?.lastName,
		validate: (field) => Validate.required(field, "Bitte geben Sie Ihren Nachnamen ein"),
	});
	const formEmail = useFormValue({
		default: props.userData?.email,
		validate: (field) => Validate.email(field, "Bitte geben Sie Ihre E-Mail-Adresse ein"),
	});
	const formTerms = useFormValue({
		default: false,
		validate: (field) => Validate.required(field, "Bitte geben Sie Ihr Einverständnis"),
	});

	async function isValid() {
		const allValidations = await Promise.all([
			formSalutation.validate(),
			formFirstName.validate(),
			formLastName.validate(),
			formEmail.validate(),
			formTerms.validate(),
		]);

		return allValidations.every((result) => result);
	}

	function transferList(checkedTagList: string[]): void {
		aboList.value = checkedTagList;
		formAboList.validate();
	}

	async function handleSubmit(): Promise<void> {
		if (!(await isValid())) return;

		isValidated.value = true;
		formErrorMessage.value = "";

		emit("submit", {
			salutation: formSalutation.fieldValue,
			firstName: formFirstName.fieldValue,
			lastName: formLastName.fieldValue,
			email: formEmail.fieldValue,
			terms: formTerms.fieldValue,
			aboList: formAboList.fieldValue.length === 0 ? props.newsletterCardAbos : formAboList.fieldValue,
		});
	}

	const userObjIncomplete = computed(() => {
		if (!props.userData?.salutation) return true;
		if (!props.userData?.firstName) return true;
		if (!props.userData?.lastName) return true;
		if (!props.userData?.email) return true;

		return false;
	});
</script>

<style lang="scss">
	@import "../../storybook/src/assets/css/mixins";

	.u-newsletter-form {
		padding: var(--space-7);
		background-color: var(--color-primary-10);
		max-width: none;

		--input-label-font-size: var(--font-size-2);
		--input-label-space: var(--space-2);

		display: flex;
		flex-direction: column;

		&__input-wrapper {
			display: flex;
			gap: var(--space-7);
			grid-column: 1 / span 2;
			margin-top: var(--space-4);

			@include tablet-below {
				flex-direction: column;
				gap: var(--space-4);
				width: 100%;
			}
		}

		&__icon {
			margin-right: var(--space-4);
			grid-column: 1;
			grid-row: 1 / span 2;
		}

		&__input-wrapper > div {
			display: flex;
			flex-direction: column;
			flex: 1;
		}

		&__submit-button {
			grid-column: 2;
			align-self: center;
			width: fit-content;
			line-height: 1rem;
			margin-top: 0.625rem;
			margin-left: 0;
			padding: 1rem 1.5rem;
		}

		&__agreement {
			font-size: var(--font-size-1);
			line-height: normal;
			margin-top: 0.625rem;

			.u-switch__label {
				margin-top: 0;
			}
		}

		&__text {
			grid-column: 2;
		}

		&__title {
			font-size: var(--font-size-6);

			@include desktop {
				font-size: var(--font-size-7);
			}
		}

		&__title-wrapper {
			display: flex;
			flex-wrap: wrap;
			column-gap: var(--space-2);
			justify-content: space-between;
			grid-column: 2;
			align-items: flex-start;

			.u-notification-time {
				&__text {
					font-size: var(--font-size-3);
					line-height: var(--font-size-7);
				}

				&__icon {
					width: 1rem;
					transform: translateY(24%);
				}
			}
		}

		&__info-text {
			--font-size-newsletter-optIn: 0.625rem;

			margin-top: var(--space-4);
			color: var(--color-neutral-50);
			font-size: var(--font-size-newsletter-optIn);
			line-height: 1.3;
			grid-column: 1 / span 2;
		}

		&__notification-time {
			font-size: var(--font-size-4);
		}

		&__meta-wrapper {
			display: grid;
			grid-template-columns: auto 1fr;
			grid-column: 1 / span 2;
		}

		&__tags {
			grid-column: 1 / span 2;
			margin-top: var(--space-2);
		}

		&__input-firstname--valid {
			border-color: green;
		}

		&__input-firstname--has-error {
			border-color: var(--color-secondary-300);
		}
	}
</style>
